import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import OurTeam from "./pages/OurTeam";
import ContactUs from "./pages/ContactUs";

function App() {
	return (
		<Router>
			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					{/* <Route path="/" element={<UNder />} /> */}
					{/* <Route path="/about" element={<About />} />
					<Route path="/projects" element={<Projects />} />
					{/* our team */}
					{/* <Route path="/our-team" element={<OurTeam />} /> */}
					{/* contact us */}
					{/* <Route path="/contact-us" element={<ContactUs />} /> */} */}
   
				</Routes>
			</Suspense>
		</Router>
	);
}

export default App;
